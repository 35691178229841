.swiper-container {
    height: 125px;
    margin-left: 0;
    margin-right: 0;
}
.swiper-container-horizontal>.swiper-pagination-bullets,
.swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: 0;
}

.swiper-button-next, .swiper-button-prev {
    top: 40%;
}

@media screen and (min-width: 768px) {
    .swiper-wrapper {
        left: 13px
    }
}

